<template>
  <div class="container process-timeline d-flex align-items-center justify-content-center"
    style="margin-bottom: 10px; margin-top: 50px;">

    <div class="row">

      <div class="progress-steps laptop">
        <ul class="d-flex justify-content-between list-unstyled">
          <template v-if="shipment.origin_country">
            <router-link to="/new_order" class="step completed">
              <img src="../assets/img/step1_c.svg"> {{ translate('shipping_route') }}
            </router-link>
          </template>
          <template v-else>
            <router-link to="/new_order" class="step inprogress">
              <img src="../assets/img/step1_c.svg"> {{ translate('shipping_route') }}
            </router-link>
          </template>

          <template v-if="shipment.origin_country">
            <template v-if="sender.first_name">
              <router-link to="/import/sender_information" class="step completed">
                <img src="../assets/img/step2_c.svg"> {{ translate('sender_information2') }}
              </router-link>
            </template>
            <template v-else>
              <router-link to="/import/sender_information" class="step inprogress">
                <img src="../assets/img/step2_c.svg"> {{ translate('sender_information2') }}
              </router-link>
            </template>
          </template>
          <template v-else>
            <li class="step not-completed">
              <img src="../assets/img/step2_ic.svg"> {{ translate('sender_information2') }}
            </li>
          </template>

          <template v-if="sender.first_name">
            <template v-if="receiver.first_name">
              <router-link to="/import/receiver_information" class="step completed">
                <img src="../assets/img/step3_c.svg"> {{ translate('receiver_information2') }}
              </router-link>
            </template>
            <template v-else>
              <router-link to="/import/receiver_information" class="step inprogress">
                <img src="../assets/img/step3_c.svg"> {{ translate('receiver_information2') }}
              </router-link>
            </template>
          </template>
          <template v-else>
            <li class="step not-completed">
              <img src="../assets/img/step3_ic.svg"> {{ translate('receiver_information2') }}
            </li>
          </template>

          <template v-if="receiver.first_name">
            <template v-if="shipment.service_point_id">
              <router-link to="/import/shipment-information" class="step completed">
                <img src="../assets/img/step4_c.svg"> {{ translate('shipment_info2') }}
              </router-link>
            </template>
            <template v-else>
              <router-link to="/import/shipment-information" class="step inprogress">
                <img src="../assets/img/step4_c.svg"> {{ translate('shipment_info2') }}
              </router-link>
            </template>
          </template>
          <template v-else>
            <li class="step not-completed">
              <img src="../assets/img/step4_ic.svg"> {{ translate('shipment_info2') }}
            </li>
          </template>

          <template v-if="shipment.service_point_id">
            <router-link to="/import/payment" class="step inprogress">
              <img src="../assets/img/step5_c.svg"> {{ translate('payment') }}
            </router-link>
          </template>
          <template v-else>
            <li class="step not-completed">
              <img src="../assets/img/step5_ic.svg"> {{ translate('payment') }}
            </li>
          </template>
        </ul>
      </div>


      <div class="progress-steps mobile">
        <ul class="d-flex justify-content-between list-unstyled">
          <template v-if="shipment.origin_country">
            <router-link to="/new_order" class="step completed">
              1
            </router-link>
          </template>
          <template v-else>
            <router-link to="/new_order" class="step inprogress">
              1
            </router-link>
          </template>

          <template v-if="shipment.origin_country">
            <template v-if="sender.first_name">
              <router-link to="/import/sender_information" class="step completed">
                2
              </router-link>
            </template>
            <template v-else>
              <router-link to="/import/sender_information" class="step inprogress">
                2
              </router-link>
            </template>
          </template>
          <template v-else>
            <li class="step not-completed">
              2
            </li>
          </template>

          <template v-if="sender.first_name">
            <template v-if="receiver.first_name">
              <router-link to="/import/receiver_information" class="step completed">
                3
              </router-link>
            </template>
            <template v-else>
              <router-link to="/import/receiver_information" class="step inprogress">
                3
              </router-link>
            </template>
          </template>
          <template v-else>
            <li class="step not-completed">
              3
            </li>
          </template>

          <template v-if="receiver.first_name">
            <template v-if="shipment.service_point_id">
              <router-link to="/import/shipment-information" class="step completed">
                4
              </router-link>
            </template>
            <template v-else>
              <router-link to="/import/shipment-information" class="step inprogress">
                4
              </router-link>
            </template>
          </template>
          <template v-else>
            <li class="step not-completed">
              4
            </li>
          </template>

          <template v-if="shipment.service_point_id">
            <router-link to="/import/payment" class="step inprogress">
              5
            </router-link>
          </template>
          <template v-else>
            <li class="step not-completed">
              5
            </li>
          </template>
        </ul>
      </div>
    </div>

  </div>
</template>


  
<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';


export default {
  name: 'NewOrder',
  mixins: [en, fr, nl],
  data() {
    return {

      shipment: {},

      sender: {
        number: "",
        first_name: "",
        last_name: "",
        street: "",
        postal_code: "",
        city: "",
        state: "",
        country: "",
        email: "",
        phone: ""
      },
      receiver: {
        number: "",
        street: "",
        postal_code: "",
        city: "",
        state: "",
        country: "",
        email: "",
        phone: ""
      },
    }
  },
  computed: {
    lang() {
      return this.$store.state.lang
    },
  },
  mounted() {
    document.title = 'Progress | Eurekali'

    this.shipment = this.$store.state.shipment

    this.receiver = this.$store.state.shipment.receiver
    this.sender = this.$store.state.shipment.sender

  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
  }
}
</script>



<style scoped>
.progress-steps ul {
  border: 1px solid #fff;
  border-radius: 30px;
  overflow: hidden;
}

.step {
  text-align: center;
  padding: 8px 16px;
  background-color: #E5097F;
  color: white;
  font-weight: bold;
  border-left: 1px solid #fff;
}

.step:first-child {
  border-left: none;
}

.completed {
  background-color: #E5097F;
  cursor: pointer;
}

.inprogress {
  background-color: #E981A4;
  cursor: pointer;
}

.not-completed {
  background-color: #aaa;
  cursor: not-allowed;
}

.step img {

  scale: 0.9;
  vertical-align: middle;
}

.mobile {
  display: none;
}

@media (max-width: 576px) {

  .step {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .laptop {
    display: none;
  }

  .mobile {
    display: block;
  }
}
</style>
