<template>
    <section style="margin-top: 30px; margin-bottom: 100px;">

        <div ref="scrollTarget"></div>
        <ProcessTimeLine />

        <div class="mobile-prices"
            style="width: 100%; background-color: #FEEEF6; text-align: center; padding-top: 10px; padding-bottom: 10px; margin-top: -20px; margin-bottom: 30px;">
            <img src="../assets/img/payment_mobile_icon.svg"> <span
                style="color: #E5097F; margin-left: 5px; font-weight: 600;">{{
                    translate("payment") }}</span>
        </div>


        <div class="container mt-4">
            <div v-if="isLoading" class="loading-overlay2">
                <img src="@/assets/img/LOADING.gif" style="scale: 0.7 ;" alt="Loading Animation" />
            </div>
            <div v-else>
                <div class="row justify-content-center">
                    <div class="col-md-8 mb-4">
                        <div class="card mb-4" style="border-radius: 20px;">
                            <div class="card-header py-3"
                                style="background-color: #E5097F; border-top-right-radius: 20px; border-top-left-radius: 20px;">
                                <h5 class="mb-0" style="color: white; font-weight: bold;"><i class="fa fa-credit-card-alt"
                                        aria-hidden="true"></i> {{ translate('bancontact') }}</h5>
                            </div>
                            <div class="card-body">


                                <div class="form-group mb-1 col-12">
                                    <div class="form-group form-switch">
                                        <input class="form-check-input" type="checkbox" v-model="discount_choice"
                                            :style="discount_choice ? { backgroundColor: '#E5097F', border: '#E5097F' } : {}">
                                        <label class="form-check-label" style="margin-left: 12px;">{{
                                            translate('enter_promotion_code') }}</label>
                                    </div>
                                </div>
                                <template v-if="discount_choice">
                                    <div class="form-group mb-3 col-12 col-md-6 mt-4">
                                        <label class="form-label">{{ translate('promo_code') }}</label>
                                        <div class="input-group">
                                            <input type="text" class="form-control"
                                                :placeholder="translate('enter_promotion_code_place')" v-model="promo_code"
                                                :class="{ 'fill-out': promo_code, 'not-fill-out': !promo_code }">
                                            <button class="btn btn-payment" @click.event="checkPromoCode">{{
                                                translate('validate_code') }}</button>
                                        </div>
                                    </div>
                                </template>

                                <hr>

                                <div class="d-flex justify-content-center" style="margin-top: 20px;">
                                    <button class="btn btn-success" @click="submitBanContact"
                                        style="border-radius: 30px;"><i class="fa fa-credit-card" aria-hidden="true"></i>
                                        {{ translate('pay_bancontact') }}</button>
                                </div>


                            </div>
                        </div>
                    </div>


                    <div class="col-md-4 mb-4">
                        <div class="card mb-4" style="background-color: #FEEEF6; border-radius: 20px;border: none;">
                            <div class="card-header py-3"
                                style="background-color: #E5097F; border-top-right-radius: 20px; border-top-left-radius: 20px;">
                                <h5 class="mb-0" style="color: white;font-weight: bold;"><i class="fa fa-bars"
                                        aria-hidden="true"></i> {{ translate('summary') }}</h5>
                            </div>
                            <div class="card-body">
                                <ul class="list-group list-group-flush">

                                    <template v-if="insure_my_parcel">
                                        <li class="d-flex justify-content-between align-items-center border-0 px-0 mb-0">
                                            <div>
                                                <strong style="color: #E5097F;"><i class="fa fa-check-circle"
                                                        aria-hidden="true"></i> {{ translate('assurance') }}</strong>
                                            </div>
                                            <span><strong style="color: #E5097F;"> {{ assurance_amount }}
                                                    &euro;</strong></span>
                                        </li>
                                    </template>
                                    <template v-else>
                                        <li class="d-flex justify-content-between align-items-center border-0 px-0">
                                            <div>
                                                <strong style="color: red;"><i class="fa fa-exclamation-triangle"
                                                        aria-hidden="true"></i> {{ translate('no_insurance') }}</strong>
                                            </div>
                                        </li>
                                    </template>

                                    <template v-if="home_collection">
                                        <li class="d-flex justify-content-between align-items-center border-0 px-0 mb-0">
                                            <div>
                                                <strong style="color: #E5097F;"><i class="fa fa-home"></i> {{
                                                    translate('home_collection') }}</strong>
                                            </div>
                                            <span><strong style="color: #E5097F;"> {{ home_collection_amount }}
                                                    &euro;</strong></span>
                                        </li>
                                    </template>
                                    <template v-if="shipment.printer_option">
                                        <li class="d-flex justify-content-between align-items-center border-0 px-0 mb-0">
                                            <div>
                                                <strong style="color: #E5097F;"><i class="fa fa-print"></i> {{
                                                    translate('printer') }}</strong>
                                            </div>
                                            <span><strong style="color: #E5097F;"> {{ shipment.printer_amount }}
                                                    &euro;</strong></span>
                                        </li>
                                    </template>

                                    <li class="d-flex justify-content-between align-items-center border-0 px-0 mb-0">
                                        <div>
                                            <strong style="color: #E5097F;">{{ translate('shipment_price') }}</strong>
                                        </div>
                                        <span><strong style="color: #E5097F;"> {{ parseFloat((parseFloat(shipment_price) +
                                            parseFloat(vat_price))).toFixed(2) }} &euro;</strong></span>
                                    </li>

                                    <template v-if="discount">
                                        <li class="d-flex justify-content-between align-items-center border-0 px-0 mb-2">
                                            <div>
                                                <strong style="color: red;">Discount</strong>
                                            </div>
                                            <span><strong style="color: red;">- {{ discount }} %</strong></span>
                                        </li>
                                        <li class="d-flex justify-content-between align-items-center border-0 px-0 mb-1"
                                            style="margin-top: 20px;">
                                            <div>
                                                <strong style="color: #E5097F;">{{ translate('total_amount') }}</strong>
                                            </div>
                                            <template v-if="home_collection">
                                                <template v-if="shipment.printer_option">
                                                    <span><strong style="color: #E5097F;">{{ ((parseFloat(price) + parseFloat(home_collection_amount) + parseFloat(shipment.printer_amount)) * (1 - (parseFloat(discount) / 100))).toFixed(2) }} &euro;</strong></span>
                                                </template>
                                                <template v-else>
                                                    <span><strong style="color: #E5097F;">{{ ((parseFloat(price) + parseFloat(home_collection_amount)) * (1 - (parseFloat(discount) / 100))).toFixed(2) }} &euro;</strong></span>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <span><strong style="color: #E5097F;">{{ ((parseFloat(price)) * (1 - (parseFloat(discount) / 100))).toFixed(2) }} &euro;</strong></span>
                                            </template>
                                        </li>
                                    </template>
                                    <template v-else>
                                        <li class="d-flex justify-content-between align-items-center border-0 px-0 mb-1"
                                            style="margin-top: 20px;">
                                            <div>
                                                <strong style="color: #E5097F;">{{ translate('total_amount') }}</strong>
                                            </div>
                                            <template v-if="home_collection">
                                                <template v-if="shipment.printer_option">
                                                    <span><strong style="color: #E5097F;">{{ (parseFloat(price) +
                                                        parseFloat(home_collection_amount) +
                                                        parseFloat(shipment.printer_amount)).toFixed(2) }}
                                                            &euro;</strong></span>
                                                </template>
                                                <template v-else>
                                                    <span><strong style="color: #E5097F;">{{ (parseFloat(price) +
                                                        parseFloat(home_collection_amount)).toFixed(2) }}
                                                            &euro;</strong></span>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <span><strong style="color: #E5097F;">{{ price }} &euro;</strong></span>
                                            </template>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
  
  
<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import moment from 'moment';
import Swal from 'sweetalert2'
import { loadStripe } from '@stripe/stripe-js';
import ProcessTimeLine from '@/components/ProcessTimeLine.vue';
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';


export default {
    name: 'NewOrder',
    mixins: [en, fr, nl],
    components: {
        ProcessTimeLine
    },
    data() {
        return {
            new_country: "",
            price: "",
            package_type: "",
            eu_country: "",
            number: "",
            street: "",
            postal_code: "",
            city: "",
            state: "",
            country: "",
            email: "",
            phone: "",
            first_name: "",
            last_name: "",
            company_name: "",
            VAT: "",
            vat_price: "",
            shipment_price: "",
            discount_choice: true,
            discount: 0,
            payment_cash: "",
            cash_token: "",
            promo_code: "",

            shipment: {},
            stripe: {},
            card: {},
            errors: [],

            shipment_id: "",
            sender_id: "",
            receiver_id: "",
            sender: {},
            receiver: {},
            service_point_id: "",
            service_point: {},

            insure_my_parcel: "",
            assurance_amount: "",

            home_collection: "",
            home_collection_amount: "",

            isLoading: false
        }
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
    },
    created() {
        if (!this.$store.state.shipment.price) {
            this.$router.push('/new_order')
        }

        if (this.$store.state.shipment.origin_country === 'MA') {
            this.$router.push('/payment')
        }
    },
    mounted() {
        document.title = 'Payment | Eurekali'
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo(0, 0)

        this.new_country = this.$store.state.shipment.country
        this.price = this.$store.state.shipment.price
        this.package_type = this.$store.state.shipment.package_type
        this.eu_country = this.$store.state.shipment.eu_country

        this.number = this.$store.state.shipment.sender.number
        this.street = this.$store.state.shipment.sender.street
        this.postal_code = this.$store.state.shipment.sender.postal_code
        this.city = this.$store.state.shipment.sender.city
        this.state = this.$store.state.shipment.sender.state
        this.country = this.$store.state.shipment.sender.country
        this.email = this.$store.state.shipment.sender.email
        this.phone = this.$store.state.shipment.sender.phone
        this.first_name = this.$store.state.shipment.sender.first_name
        this.last_name = this.$store.state.shipment.sender.last_name
        this.company_name = this.$store.state.shipment.sender.company_name
        this.choice = false

        this.VAT = this.$store.state.shipment.VAT
        this.assurance_amount = this.$store.state.shipment.assurance_amount
        if (this.$store.state.shipment.insure_my_parcel) {
            this.shipment_price = parseFloat(parseFloat(parseFloat(this.price) / parseFloat(1 + parseFloat(this.VAT) / 100)) - parseFloat(this.assurance_amount)).toFixed(2)
        } else {
            this.shipment_price = parseFloat(parseFloat(this.price) / parseFloat(1 + parseFloat(this.VAT) / 100)).toFixed(2)
        }
        this.vat_price = parseFloat(parseFloat(this.price) - parseFloat(parseFloat(this.price) / parseFloat(1 + parseFloat(this.VAT) / 100))).toFixed(2)


        this.shipment = this.$store.state.shipment
        this.sender = this.$store.state.shipment.sender
        this.receiver = this.$store.state.shipment.receiver
        this.service_point_id = this.$store.state.shipment.service_point_id

        this.home_collection = this.$store.state.shipment.home_collection
        this.home_collection_amount = this.$store.state.shipment.home_collection_amount

        this.insure_my_parcel = this.$store.state.shipment.insure_my_parcel

        const shipment_idd = this.$route.params.shipment_id;
        const payment_intent = this.$route.query.payment_intent;
        const payment_intent_client_secret = this.$route.query.payment_intent_client_secret;

        if (this.$store.state.shipment.price) {
            if (shipment_idd && payment_intent && payment_intent_client_secret) {
                this.shipment_id = shipment_idd
                this.checkPaymentStatus(shipment_idd, payment_intent, payment_intent_client_secret)
            }
        }

    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        showAlertSuccess() {
            Swal.fire({
                title: this.translate('payment_success'),
                text: this.translate('payment_success_cong'),
                icon: 'success',
                confirmButtonText: 'OK',
                confirmButtonColor: '#4BB543',
            }).then(() => {
                // Redirect to /new_order
                this.$router.push('/success/' + 'E132A481484Z' + this.shipment_id + 'C48484S12');
            });
        },
        showAlertFailer() {
            Swal.fire({
                title: this.translate('payment_failed'),
                text: this.translate('payment_failed_txt'),
                icon: 'error',
                confirmButtonText: 'OK'
            }).then(() => {
                // Redirect to /new_order
                this.$router.push('/payment');
            });
        },
        async CreateSenderClient() {
            const data = {
                'first_name': this.sender.first_name,
                'last_name': this.sender.last_name,
                'company_name': this.sender.company_name,
                'email': this.sender.email,
                "phone": this.sender.extention + this.sender.phone,
                "number": this.sender.number,
                "street": this.sender.street,
                "city": this.sender.city,
                "state": this.sender.state,
                "postal_code": this.sender.postal_code,
                "country": this.sender.country,
                "client_type": "sender",
            };
            try {
                await axios.post('/api/v1/clients/create/', data)
                    .then(response => {
                        if (response.data['client_id']) {
                            this.sender_id = response.data['client_id']
                        } else {
                            toast({
                                message: 'Problem in Sender Information!',
                                type: 'is-danger',
                                dismissible: true,
                                pauseOnHover: true,
                                duration: 2000,
                                position: 'top-right',
                            })
                        }
                    })
            } catch (error) {
                this.errors.push('Something went wrong. Client creation');
                console.error(error);
            }

            this.$store.commit('setIsLoading', false);
        },
        async CreateReceiverClient() {
            const data = {
                'first_name': this.receiver.first_name,
                'last_name': this.receiver.last_name,
                'company_name': this.receiver.company_name,
                'email': this.receiver.email,
                "phone": this.receiver.extention + this.receiver.phone,
                "number": this.receiver.number,
                "street": this.receiver.street,
                "city": this.receiver.city,
                "state": this.receiver.state,
                "postal_code": this.receiver.postal_code,
                "country": this.receiver.country,
                "client_type": "receiver",
            };
            try {
                await axios.post('/api/v1/clients/create/', data)
                    .then(response => {
                        if (response.data['client_id']) {
                            this.receiver_id = response.data['client_id']
                        } else {
                            toast({
                                message: 'Problem in Receiver Information!',
                                type: 'is-danger',
                                dismissible: true,
                                pauseOnHover: true,
                                duration: 2000,
                                position: 'top-right',
                            })
                        }
                    })
            } catch (error) {
                this.errors.push('Something went wrong. Client creation');
                console.error(error);
            }
            this.$store.commit('setIsLoading', false);
        },
        async getServicePoint() {
            this.$store.commit('setIsLoading', true)

            await axios.get(`/api/v1/servicepoints/${this.service_point_id}`)
                .then(response => {
                    if (response.data) {
                        this.service_point = response.data
                    } else {
                        toast({
                            message: 'Service Point is not valide!',
                            type: 'is-danger',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'top-right',
                        })
                    }
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        },
        async CreateShipment() {
            await this.CreateSenderClient()
            await this.CreateReceiverClient()
            await this.getServicePoint()

            let data_v1 = ""
            if (this.shipment.home_collection) {

                data_v1 = {
                    'insure_my_parcel': this.shipment.insure_my_parcel,
                    'sender': this.sender_id,
                    'recipient': this.receiver_id,
                    'pickup_number': this.sender.number,
                    "pickup_street": this.sender.street,
                    "pickup_postal_code": this.sender.postal_code,
                    "pickup_city": this.sender.city,
                    "pickup_state": this.sender.state,
                    "pickup_country": this.sender.country,
                    "country": this.shipment.country,
                    "package_type": this.shipment.package_type,
                    "max_weight": this.shipment.weight,
                    "home_collection": this.shipment.home_collection,
                    "printer": this.shipment.printer_option,
                    "express": this.shipment.express,
                    "origin_country" : this.shipment.origin_country,

                    "promo_code": this.promo_code,
                    "package_description": this.shipment.description,
                }

            } else {
                data_v1 = {
                    'insure_my_parcel': this.shipment.insure_my_parcel,
                    'sender': this.sender_id,
                    'recipient': this.receiver_id,
                    'pickup_number': this.service_point.number,
                    "pickup_street": this.service_point.street,
                    "pickup_postal_code": this.service_point.postal_code,
                    "pickup_city": this.service_point.city,
                    "pickup_state": this.service_point.state,
                    "pickup_country": this.service_point.country,
                    "country": this.shipment.country,
                    "package_type": this.shipment.package_type,
                    "max_weight": this.shipment.weight,
                    "home_collection": this.shipment.home_collection,
                    "printer": this.shipment.printer_option,
                    "express": this.shipment.express,
                    "origin_country" : this.shipment.origin_country,

                    "promo_code": this.promo_code,
                    "package_description": this.shipment.description,
                }
            }

            const data = data_v1

            try {
                await axios.post('/api/v1/shipments/create/', data)
                    .then(response => {
                        if (response.data['id']) {
                            this.shipment_id = response.data['id']
                        } else {
                            toast({
                                message: 'Problem in creating shipment!',
                                type: 'is-danger',
                                dismissible: true,
                                pauseOnHover: true,
                                duration: 2000,
                                position: 'top-right',
                            })
                        }
                    })
            } catch (error) {
                this.errors.push('Something went wrong. shipment creation ',);
                console.error(error);
            }
            this.$store.commit('setIsLoading', false);
        },
        async createServicePointShipment() {
            await axios
                .post(`/api/v1/service-point-shipment/create/${this.shipment.service_point_id}/${this.shipment_id}/`, {

                })
                .then(response => {
                    // Handle the response
                    console.log(response.data); // Process the response as needed
                })
                .catch(error => {
                    console.error(error);
                    // Handle error
                });
        },
        async createInvoice() {
            axios
                .post(`/api/v1/invoices/create/${this.shipment_id}/${this.service_point_id}/`, {

                })
                .then(response => {
                    console.log(response.data); // Process the response as needed
                })
                .catch(error => {
                    console.error(error);
                    // Handle error
                });
        },
        async createSaleticket() {
            await axios
                .post(`/api/v1/saleticket/create/${this.shipment_id}/`, {

                })
                .then(response => {
                    console.log(response.data); // Process the response as needed
                })
                .catch(error => {
                    console.error(error);
                    // Handle error
                });
        },
        async CreateProforma() {

            const data = {
                'shipment': this.shipment_id,
                "nature": this.shipment.proforma.item_nature,
                "description": this.shipment.proforma.item_description,
                "hs_code": this.shipment.proforma.item_code,
                "number_items": this.shipment.proforma.item_number,
                "unit_price": this.shipment.proforma.item_price,
            }

            try {
                await axios.post('/api/v1/proforma-invoice/create/', data)
                    .then(response => {

                    })
            } catch (error) {
                this.errors.push('Something went wrong. Proforma creation ');
                console.error(error);
            }
            this.$store.commit('setIsLoading', false);
        },
        //normal methods
        delay(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async checkPromoCode() {
            event.preventDefault();
            this.$store.commit('setIsLoading', true)

            await axios.get(`/api/v1/verify-promo/${this.promo_code}`)
                .then(response => {
                    if (response.data['valid']) {
                        this.discount = response.data['amount']
                        toast({
                            message: this.translate('alert23') + response.data['amount'] + '%',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'top-right',
                        })
                    } else {
                        this.discount = 0

                        toast({
                            message: this.translate('alert24'),
                            type: 'is-danger',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'top-right',
                        })
                    }

                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        },
        //Cash Payment
        async submitBanContact() {
            try {

                await this.CreateShipment()
                await this.handlePayment()

            } catch (error) {
                await axios.post(`/api/v1/shipments/failed/payment/${this.shipment_id}/`);
                this.errors.push('Something went wrong. Please try again');
                console.error(error);
            }

            this.$store.commit('setIsLoading', false);
            this.isLoading = false;
        },
        async BancontactHandler(token) {
            this.isLoading = true;
            this.$store.commit('setIsLoading', true)

            const data = {
                'first_name': this.first_name,
                'last_name': this.last_name,
                'email': this.email,
                "phone": this.phone,
                "number": this.number,
                "street": this.street,
                "city": this.city,
                "state": this.state,
                "postal_code": this.postal_code,
                "country": this.country,
                "paid_amount": parseFloat(this.price) - parseFloat(this.discount),
                'stripe_token': token
            };

            try {

                await this.createServicePointShipment()

                await axios.post(`/api/v1/paid-shipment/${this.shipment_id}/`, data);


                await this.createSaleticket()
                //await this.createInvoice()
                if (this.shipment.proforma.item_nature) {
                    await this.CreateProforma()
                }

                axios.post(`/api/v1/shipment/sendemails/${this.shipment_id}/`);
                await this.delay(7000);

                this.$store.commit('clearShipment')
                this.showAlertSuccess()


            } catch (error) {

                await axios.post(`/api/v1/shipments/failed/payment/${this.shipment_id}/`);

                this.errors.push('Something went wrong. Please try again');
                console.error(error);
            }

            this.$store.commit('setIsLoading', false);
            this.isLoading = false;
        },
        async handlePayment() {
            this.isLoading = true;
            const stripe = await loadStripe('pk_live_ibgaILxnep47lmU6984EsQdS00SLZwStBM');

            try {

                const response = await axios.post(`/api/v1/chekout/bancontact/${this.shipment_id}/`);
                const responseData = response.data;
                if (responseData.error) {
                    console.error('Error:', responseData.error);
                } else {
                    try {
                        const result = await stripe.confirmBancontactPayment(responseData.client_secret, {
                            payment_method: {
                                billing_details: {
                                    name: this.shipment.sender.first_name + " " + this.shipment.sender.last_name,
                                },
                            },
                            return_url: 'https://eurekali-point.com/payment/bancontact/' + this.shipment_id,
                        });

                        if (result.error) {
                            console.error('Error:', result.error);
                        } else if (result.paymentIntent.status === 'succeeded') {
                            console.log('Payment successful!');
                            // Display success message or perform any other actions
                        }
                    } catch (error) {
                        console.error('Error:', error);
                    }
                }
            } catch (error) {
                console.error('Error:', error);
            }
        },
        async checkPaymentStatus(shipment_id, payment_intent_id, payment_intent_client_secret) {

            await axios.get(`/api/v1/checkpayment/bancontact/${shipment_id}/${payment_intent_id}/${payment_intent_client_secret}/`)
                .then(response => {
                    console.log(response.data);

                    if (response.data['status'] == true) {
                        this.BancontactHandler(payment_intent_client_secret)
                    } else {
                        this.showAlertFailer()
                    }

                })
                .catch(error => {
                    console.error(error);
                    this.showAlertFailer()
                });
        },
    }
}
</script>
  
  
<style>
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
